export default {
	name: "DecksAndSlides",
	created () {
		this.$nextTick(function () {

		});
	},
	mounted () {
		this.$nextTick(function () {

		})
	},
	data () {
		return {
			PDFsrc: '/BUR007 - Infographic_SW_Final Output.pdf',
			imgPDF: [
				{
					img: require('../../../../../public/image/Infographics1.png'),
					alt: 'WHAT IS A STRUCTURED WARRANT (SW)?'
				},
				{
					img: require('../../../../../public/image/Infographics2.png'),
					alt: 'BENEFIT OF INVESTING IN SW'
				},
				{
					img: require('../../../../../public/image/Infographics3.png'),
					alt: 'SW VALUE'
				},
				{
					img: require('../../../../../public/image/Infographics4.png'),
					alt: 'EFFECTIVE GEARING'
				},
				{
					img: require('../../../../../public/image/Infographics5.png'),
					alt: 'AN ILLUSTRATION ON ESTIMATING PROFITS FROM THE LIVE PRICE MATRIX'
				},
			]
		};
	},
	computed: {

	},
	watch: {

	},
	methods: {
		// 获取数据

	}
};